import Image from 'next/legacy/image';
import { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import { useTranslation } from 'src/hooks/translation';
import { Text } from 'styles/typography';
import * as UI from 'styles/header';
import { useOpenSidebar } from 'store/common';
import { Breadcrumb } from 'views/components/Breadcrumbs';
import { ROUTES } from 'utils/constants/routes';

import useDropDownMenu from './hooks/useHeaderDropMenu';

const AddNewSidebar = dynamic(() => import('views/features/AddNewSidebar/AddNewSidebar'));

const Header = () => {
  const t = useTranslation();

  const [open, setOpen] = useState(false);

  const { RenderDropMenu } = useDropDownMenu();

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  const { data: isOpen, mutate } = useOpenSidebar();

  const handleOpening = () => mutate(!isOpen, false);

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <UI.Header>
      <UI.LogoWrapper $width={isOpen ? 180 : 62}>
        <Link href={ROUTES.main} passHref>
          <UI.LogoImage $width={isOpen ? 75 : 0}>
            <Image src={'/icons/logo/main-logo.svg'} layout="fill" alt={'Logo'} />
          </UI.LogoImage>
        </Link>
        <UI.OpenWrapper $isOpen={!!isOpen}>
          <Image
            src={'/icons/sidebar/open-sidebar.svg'}
            alt={'arrow'}
            layout="fill"
            onClick={handleOpening}
          />
        </UI.OpenWrapper>
      </UI.LogoWrapper>
      <UI.Content>
        <UI.Left>
          <Breadcrumb />
        </UI.Left>
        <UI.Right>
          <RenderDropMenu />
          <UI.AddNewButton onClick={toggleOpen} data-testid="add-new-entity-button">
            <Text lineHeight="md"> + {t.addNewSidebar.addNewButtonText}</Text>
          </UI.AddNewButton>
        </UI.Right>
      </UI.Content>

      {open && <AddNewSidebar onClose={handleClose} />}
    </UI.Header>
  );
};

export default Header;
